import * as React from 'react';

import Fade from '../Fade';
import * as styles from './index.module.css';
import type { Timeline as TimelineType } from '../../types';

type Props = {
  timeline: TimelineType[];
};

const Timeline: React.FC<Props> = ({ timeline }) => {
  return (
    <div>
      {timeline.map((item) => (
        <Fade key={item.title}>
          <span className={styles.time}>{item.time}</span>
          <dl className={styles.text}>
            <dt className={styles.title}>{item.title}</dt>
            <dd className={styles.description}>{item.description}</dd>
          </dl>
        </Fade>
      ))}
    </div>
  );
};

export default Timeline;
