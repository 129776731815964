import * as React from 'react';
import { Fade as AwesomeFade } from 'react-awesome-reveal';

type Props = {
  once?: boolean;
};

const Fade: React.FC<Props> = ({ children, once = true }) => {
  return (
    <AwesomeFade direction="up" triggerOnce={once}>
      {children}
    </AwesomeFade>
  );
};

export default Fade;
