import * as React from 'react';
import { graphql, PageProps } from 'gatsby';

import Page from '../../components/Page';
import Section from '../../components/Section';
import Timeline from '../../components/Timeline';
import { getCmsDocuments, getLayout } from '../../cms';
import { useTimeline } from '../../hooks/data';
import type { AboutIntro } from '../../types';

const AboutPage: React.FC<PageProps> = ({ data }) => {
  const documents = getCmsDocuments(data);
  const [{ title, html }] = getLayout(
    documents,
    '/activities/intro/'
  ) as AboutIntro[];
  const timeline = useTimeline();

  return (
    <Page title={title}>
      <Section>
        <Section.Title>{title}</Section.Title>
        <Section.Description dangerouslySetInnerHTML={{ __html: html }} />
      </Section>
      <Section>
        <Timeline timeline={timeline} />
      </Section>
    </Page>
  );
};

export const pageQuery = graphql`
  query aboutPage {
    allMarkdownRemark(
      filter: { fields: { filePath: { in: ["/activities/intro/"] } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
          }
          fields {
            filePath
          }
        }
      }
    }
  }
`;

export default AboutPage;
